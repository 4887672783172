<template>
  <v-container class="pa-0">
    <div 
      class="img-box2"
      :class="{'pa-0 ml-8 mt-6': $vuetify.breakpoint.smAndDown}" 
    >
      <v-hover v-slot="{ hover }">
        <v-card
          class="mx-auto"
          style="cursor:pointer"
          color="lighten-4"
          max-width="600"
        >
          <v-img
            :aspect-ratio="4/3"
            src="@/assets/images/store_b.jpg"
          >
            <v-col 
              class="store-img-text pl-4 font-weight-bold"
              :class="{'text-caption text-md-subtitle-2 ': $vuetify.breakpoint.smAndDown}"  
            >(본사직영) 아크키친 B지점
            </v-col>
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-caption text-md-body-1 black--text pl-4 pr-2 pt-2 align-start pb-4"
                style="height: 84%;"
              >
                <v-col cols="12" class="pl-4">
                  <v-col class="pa-0 mb-4 d-flex align-center font-weight-bold"> 전라북도 전주시 효자로 161, 206호</v-col>
                  <v-row class="pa-0 pl-2 mt-2 mb-2">
                    <v-col cols="6" class="pa-0 d-flex align-center">
                      <v-icon color="primary-blue">
                       mdi-map-marker
                      </v-icon>
                      <span class="text-caption text-md-body-1 pl-2">도청인근</span>
                    </v-col>
                    <v-col cols="6" class="pa-0 d-flex align-center">
                      <v-icon color="primary-blue">
                        mdi-thumb-up 
                      </v-icon>
                      <span class="text-caption text-md-body-1 pl-2">1등급
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 pl-2 mt-2 mb-2">
                    <v-col cols="6" class="pa-0 d-flex align-center">
                      <v-icon color="primary-blue">
                        mdi-store-clock
                      </v-icon>
                      <span class="text-caption text-md-body-1 pl-2">연중무휴·24시간</span>
                    </v-col>
                    <v-col cols="6" class="pa-0 d-flex align-center">
                      <v-icon color="primary-blue">
                        mdi-parking 
                      </v-icon>
                      <span class="text-caption text-md-body-1 pl-2">주차장 넓음
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 pl-2 mt-2 mb-2">
                    <v-col cols="6" class="pa-0 d-flex align-center">
                      <v-icon color="primary-blue">
                        mdi-store
                      </v-icon>
                      <span class="text-caption text-md-body-1 pl-2">9개호실</span>
                    </v-col>
                    <v-col cols="6" class="pa-0 d-flex align-center">
                      <v-icon color="primary-blue">
                        mdi-cash 
                      </v-icon>
                      <span class="text-caption text-md-body-1 pl-2">월 33만원부터~
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 pl-2 mt-2 mb-2">
                    <v-col cols="12" class="pa-0 d-flex align-center">
                      <v-icon color="primary-blue">
                        mdi-plus-circle
                      </v-icon>
                      <span class="text-caption text-md-body-1 pl-2">중앙공조, 공용인터넷, 공용cctv, 개별전기···</span>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 mb-2">
                    <v-col cols="12" class="pa-0 d-flex justify-center align-center">
                      <v-btn 
                        class="text-caption text-subtitle-2 font-weight-bold mr-2"
                        color="primary" 
                        tile 
                        elevation="0"
                        @click="navigateTo('/store-infomation')" 
                      >자세히 보기
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </v-expand-transition>
          </v-img>
        </v-card>
      </v-hover>
    </div>
        <!-- <v-col 
          cols="2" 
          class="d-flex justify-center align-center cursor"
          :class="{'pa-0 mt-8': $vuetify.breakpoint.smAndDown}"
          @click="navigateTo('/franchise')"
        >
          <v-icon large color="blue darken-2"> mdi-plus-circle </v-icon>
        </v-col> -->
  </v-container>
</template>

<script>

export default {
  name: 'MainStoreInfoSection', 
  components: {
   
  },
  mounted(){

  },
  computed: {

  },
  data() {
    return {

    }
  },
  methods: {
    navigateTo(url) { 
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.contents-box {
  max-width: 1000px !important;
}
.store-img-text {
  width: 100%;
  background-color: white;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
  background-color: #f4f7fa;
}
.m-side {
  width: 300px;
}
.round_box {
  background-color: white;
  border-radius: 8px;
}
.wrap-box {
  width: 500px !important;
}
.img-box2 {
  width: 380px;
  right: 0 !important;
}
</style>